.landing-page {
  font-size: 12px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.landing-page {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  color: #000000;
  overflow: hidden;
}

.landing-page nav {
  box-sizing: border-box;
}

.landing-page div {
  box-sizing: border-box;
}

.landing-page section {
  box-sizing: border-box;
  z-index: 6;
}

.landing-page a {
  color: #000000;
  text-decoration: none;
  transition: transform 0.2s ease;
}

.landing-page a:hover>img {
  text-decoration: none;
  transform: scale(1.03);
}

.landing-page a>img {
  transition: transform 0.2s ease;
}

.landing-page #main-nav {
  display: none;
}

.landing-page input,
.landing-page textarea,
.landing-page select {
  font-family: "Inter", sans-serif;
  outline: none;
  box-sizing: border-box;
}

.landing-page p {
  margin: 0 0 30px 0;
  padding: 0;
  font-size: calc(1.38rem * 0.75);
  line-height: 1.67;
}

.landing-page p:last-child {
  margin-bottom: 0px;
}

.landing-page i {
  font-style: italic;
}

.landing-page strong,
.landing-page b {
  font-weight: 700;
}

.landing-page h1,
.landing-page h2,
.landing-page h3,
.landing-page h4,
.landing-page h5,
.landing-page h6 {
  font-family: "Inter", sans-serif;
  line-height: 1.11;
}

.landing-page h1,
.landing-page .h1 {
  margin: 0 0 20px 0;
  font-size: calc(4.416rem * 0.75);
}

.landing-page h2 {
  margin: 0 0 15px 0;
  font-size: calc(3.66rem * 0.75);
}

.landing-page h3,
.landing-page h3.h1 {
  margin: 0 0 15px 0;
  font-size: calc(2.75rem * 0.75);
}

.landing-page h4 {
  margin: 0 0 calc(1rem * 0.75);
  font-size: calc(1.67rem * 0.75);
}

.landing-page h5 {
  margin: 0 0 calc(1rem * 0.75);
  font-size: calc(1.2rem * 0.75);
}

.landing-page .image-link {
  font-size: 0;
}

.landing-page .inp-link {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
}

.landing-page .button-link {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.landing-page .button-link:hover {
  opacity: 0.8;
}

.landing-page .btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: calc(12.41rem * 0.75);
  min-width: calc(6rem * 0.75);
  width: 100%;
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 3px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: calc(1.08rem * 0.75);
  line-height: 1;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.landing-page .btn--orange {
  background: #EE9844;
  color: white;
  border: 1px solid #EE9844;
}

.landing-page .btn--white {
  background-color: white;
  border: 1px solid #EE9844;
  color: #EE9844;
}

.landing-page .btn--orange:hover {
  background-color: white;
  border: 1px solid #EE9844;
  color: #EE9844;
}

.landing-page .btn--white:hover {
  background: #EE9844;
  color: white;
  border: 1px solid #EE9844;
}

.landing-page .btn--fit {
  max-width: fit-content;
  width: 100%;
  padding: 15px 22px;
  background-color: transparent;
  color: white;
  border: 1px solid #FFFFFF;
}

.landing-page .btn--big {
  max-width: 196px;
  width: 100%;
  font-size: calc(1.33rem * 0.75);
}

.landing-page .button-link {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.landing-page .btn--fit:hover {
  background-color: #FFFFFF;
  color: #EE9844;
}

.landing-page .btn:disabled {
  opacity: 0.5;
}

.landing-page img {
  max-width: 100%;
}


/* correctors */

.landing-page .pos-rel {
  position: relative !important;
}

.landing-page .w-100 {
  width: 100% !important;
}

.landing-page .h-100 {
  height: 100% !important;
}

.landing-page .mr-15 {
  margin-right: 15px !important;
}

.landing-page .weight-900 {
  font-weight: 900 !important;
}

.landing-page .weight-800 {
  font-weight: 800 !important;
}

.landing-page .weight-700 {
  font-weight: 700 !important;
}

.landing-page .weight-600 {
  font-weight: 600 !important;
}

.landing-page .weight-500 {
  font-weight: 500 !important;
}

.landing-page .weight-400 {
  font-weight: 400 !important;
}

.landing-page .weight-300 {
  font-weight: 300 !important;
}

.landing-page .mr-30 {
  margin-right: 30px !important;
}


@media (max-width: 1000px) {
  .landing-page p {
    font-size: calc(1.38rem * 0.6875);
  }

  .landing-page h3,
  .landing-page h3.h1 {
    font-size: calc(2.75rem * 0.6875);
  }

  .landing-page h4 {
    font-size: calc(1.67rem * 0.6875);
  }

  .landing-page h5 {
    font-size: calc(1.2rem * 0.6875);
  }
}

@media (max-width: 991.98px) {
  .landing-page p {
    margin: 0 0 20px 0;
  }
}

@media (max-width: 768px) {
  .landing-page p {
    font-size: calc(1.38rem * 0.625);
  }

  .landing-page h3,
  .landing-page h3.h1 {
    font-size: calc(2.75rem * 0.625);
  }

  .landing-page h4 {
    font-size: calc(1.67rem * 0.625);
  }

  .landing-page h5 {
    font-size: calc(1.2rem * 0.625);
  }
}

@media (max-width: 600px) {
  .landing-page p {
    font-size: calc(1.38rem * 0.5625);
  }

  .landing-page h3,
  .landing-page h3.h1 {
    font-size: calc(2.75rem * 0.5625);
  }

  .landing-page h4 {
    font-size: calc(1.67rem * 0.5625);
  }

  .landing-page h5 {
    font-size: calc(1.2rem * 0.5625);
  }
}