/* header */
.landing-page header {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 999;
}

.landing-page .container {
  max-width: 100%;
}

.landing-page .header-fixed-space {
  margin-top: 80px;
}

.landing-page .wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.landing-page .wrapper--header {
  max-width: calc(1185px * 1.125);
}

.landing-page .container {
  padding: 0 15px;
}

.landing-page .header__block {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.landing-page #head-nav ul {
  list-style-type: none;
  padding: 0px;
  display: flex;
  margin: 0;
}

.landing-page .logo_head {
  max-width: calc(12.41rem * 0.75);
  margin-right: 20px;
}

.landing-page .nav_head {
  max-width: calc(12.41rem * 0.75 * 2 + 15px);
  width: 100%;
}

.landing-page .nav_head li {
  width: 100%;
}

/* Discover */
.landing-page .discover__block-btns {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.landing-page .discover {
  padding-top: calc(9.16rem * 0.75);
}

.landing-page .wrapper--discover {
  width: 100%;
  max-width: 100%;
  margin: 0 0 0 auto;
}

.landing-page .color-orange {
  color: #EE9844;
}

.landing-page .color-black {
  color: #030202;
}

.landing-page .color-silver {
  color: #9A9A9A;
}

.landing-page .discover__block {
  display: flex;
  justify-content: flex-end;
}

.landing-page .discover__block-text {
  max-width: 46%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.landing-page .discover__block-text-size {
  max-width: calc(480px * 1.125);
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.landing-page .discover__block-image {
  max-width: 54%;
  width: 100%;
}

/* communities */
.landing-page .communities {
  margin: 40px 0 80px 0;
  width: 96.8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-page .bg-orange {
  background-color: #EE9844;
  color: white;
}

.landing-page .bg-ellipse {
  background-position: -250px center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.landing-page .comm-imgs {
  display: flex;
  border-radius: 22px;
}

.landing-page .comm-text {
  max-width: calc(52% + 48px);
  padding: 20px 15px;
  margin: 0 0px 0 auto;
  width: 100%;
}

.landing-page .list-btns {
  display: flex;
  flex-wrap: wrap;
}

.landing-page .list-btns a {
  margin-right: 10px;
  margin-bottom: 10px;
}

.landing-page .comm-text-size {
  max-width: calc(630px * 1.125);
  width: 100%;
}

.landing-page .text-md {
  font-size: calc(1.25rem * 0.75);
}

.landing-page .text-mdd {
  font-size: calc(1.66rem * 0.75);
}

.landing-page .text-lg {
  font-size: calc(1.91rem * 0.75);
}

.landing-page .comm-img1,
.landing-page .comm-img2 {
  position: absolute;
}

.landing-page .comm-img1 {
  left: 65px;
  top: -65px;
}

.landing-page .comm-img2 {
  right: 14px;
  bottom: -82px;
}

/* oipub */
.landing-page .oipub {
  padding: 30px 0 40px 0;
}

.landing-page .wrapper--oi {
  max-width: 1210px;
  margin: 0 auto;
}

.landing-page .oi-parts {
  display: flex;
  align-items: flex-end;
}

.landing-page .oi-text-left {
  max-width: calc(572px * 1.125);
  margin-right: calc(95px * 1.125);
  width: 100%;
}

.landing-page .oi-text-right {
  border-radius: 12px;
  border: 2px #EE9844 solid;
  max-width: calc(462px);
  padding: 33px 33px;
  width: 100%;
}

.landing-page .oi-text-right ul {
  list-style-type: disc;
  margin: 0;
  padding: 0;
  padding-left: calc(2rem * 0.75);
}

.landing-page .oi-text-right ul li,
.landing-page .oi-text-right {
  color: #454545;
  font-size: calc(1.33rem * 0.75);
  line-height: 1.5;
  font-weight: 500;
}

.landing-page .oi-text-left p {
  font-size: calc(1.166rem * 0.75);
  line-height: 2;
}

/* partners */
.landing-page .partners {
  padding: 50px 0px 30px 0;
}

.landing-page .partners-image {
  margin: 0 17.5px;
  width: 133px;
  height: 133px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}

.landing-page .partners-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* topics--sect */
.landing-page .topics--sect .topics {
  display: flex;
  flex-direction: row-reverse;
  margin: 40px 0 80px auto;
}

.landing-page .topics .comm-text {
  margin: 0 30px 0 auto;
}

.landing-page .topics.bg-ellipse {
  background-position: right center;
}

.landing-page .topics .list-btns {
  justify-content: flex-end;
}

.landing-page .topics .comm-text-size {
  text-align: right;
}

.landing-page .top-img1 {
  left: -25px;
  top: -65px;
}

/* footer */
.landing-page footer {
  padding: 25px 0 40px 0;
}

.landing-page .footer-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.landing-page .footer-right {
  display: flex;
}

.landing-page .footer-right__text {
  max-width: 152px;
  width: 100%;
  margin-right: 15px;
  color: rgba(26, 32, 44, 0.50);
  font-size: 7px;
}

.landing-page .footer-right__image {
  max-width: 60px;
  width: 100%;
}

.landing-page .wrapper--footer {
  max-width: 1170px;
}

.landing-page .footer-left {
  font-size: calc(1.25rem * 0.75);
  color: #B3B3B3;
}

/* say */
.landing-page .say {
  padding: 100px 0;
}

.landing-page .wrapper--say {
  max-width: 1140px;
}

.landing-page .say-parts {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.landing-page .say-text {
  max-width: 572px;
  margin-right: 50px;
  margin-bottom: 70px;
}

.landing-page .say-img img {
  position: relative;
  z-index: 3;
  max-width: 503px;
  width: 100%;
}

.landing-page .say-img1 {
  z-index: 2;
  right: -80px;
  top: -165px;
  position: absolute;
}

/* Tooltip styling */
.landing-page [data-tooltip]:before,
.landing-page [data-tooltip]:after {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  transition: 0.4s ease-in-out 0.2s;
}

.landing-page [data-tooltip]:before {
  content: "";
  height: 0;
  width: 0;
  bottom: calc(100% - 4px);
  border-style: solid;
  border-width: 8px;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.landing-page [data-tooltip]:after {
  content: attr(data-tooltip);
  background: rgba(0, 0, 0, 0.8);
  padding: calc(1.2rem * 0.75) calc(1.5rem * 0.75);
  font-size: calc(1rem * 0.75);
  line-height: 1.2;
  box-sizing: border-box;
  bottom: calc(100% + 11px);
  color: #fff;
  border-radius: 10px;
  z-index: 9;
  text-align: center;
  max-width: 100%;
  width: 100%;
}

.landing-page a:hover[data-tooltip]:before,
.landing-page a:hover[data-tooltip]:after {
  opacity: 1;
  visibility: visible;
  transition: 0.4s ease-in-out 0.2s;
}

/* media queries */
@media (max-width: 1250px) {
  .landing-page .communities {
    width: 100%;
  }
}

@media (max-width: 1150px) {
  .landing-page .communities {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
  }

  .landing-page .topics--sect .topics {
    flex-direction: column-reverse;
  }

  .landing-page .topics .comm-text {
    margin: 0 0px 0 auto;
  }

  .landing-page .comm-text,
  .landing-page .comm-text-size {
    max-width: 100%;
  }

  .landing-page .bg-ellipse {
    background-size: 135%;
    background-position: left center;
  }

  .landing-page .comm-imgs {
    margin: 0px 0 80px 0;
  }

  .landing-page .oipub {
    padding: 50px 0;
  }

  .landing-page .say-img1 {
    z-index: 2;
    right: 40px;
    top: -60px;
    position: absolute;
    max-width: 50%;
  }

  .landing-page .say-text {
    margin-bottom: 0;
  }

  .landing-page .topics--sect .topics {
    margin: 40px 0 20px auto;
  }
}

@media (max-width: 1000px) {


  .landing-page .oi-text-left p {
    font-size: calc(1.166rem * 0.6875);
  }

  .landing-page p {
    font-size: calc(1.38rem * 0.6875);
  }

  .landing-page h1 {
    font-size: calc(3.5rem * 0.6875);
  }

  .landing-page h2 {
    font-size: calc(3rem * 0.6875);
  }

  .landing-page .btn {
    font-size: calc(1.08rem * 0.6875);
  }

  .landing-page .btn--big {
    font-size: calc(1.33rem * 0.6875);
  }

  .landing-page .text-md {
    font-size: calc(1.25rem * 0.6875);
  }

  .landing-page .text-mdd {
    font-size: calc(1.66rem * 0.6875);
  }

  .landing-page .text-lg {
    font-size: calc(1.91rem * 0.6875);
  }

  .landing-page .oi-text-right ul li,
  .landing-page .oi-text-right {
    font-size: calc(1.33rem * 0.6875);
  }

  .landing-page .oi-text-left p {
    font-size: calc(1.166rem * 0.6875);
  }

  .landing-page .discover {
    padding-top: calc(9.16rem * 0.6875);
  }

  .landing-page .say-text {
    max-width: 430px;
  }

  .landing-page .say-parts {
    align-items: center;
  }

  .landing-page .say-img1 {
    z-index: 2;
    right: 40px;
    top: -50px;
    position: absolute;
    max-width: 50%;
  }
}

@media (max-width: 900px) {
  .landing-page .oi-parts {
    flex-direction: column;
  }

  .landing-page .oi-text-left {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .landing-page .oi-text-right {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .landing-page .header__block {
    padding: 10px 0;
  }

  .landing-page .header-fixed-space {
    margin-top: 60px;
  }

  .landing-page .comm-img1 img,
  .landing-page .comm-img2 img {
    max-width: 70%;
  }

  .landing-page .comm-img1 {
    left: 30px;
    top: -30px;
  }

  .landing-page .comm-img2 {
    right: 14px;
    bottom: -42px;
  }

  .landing-page .comm-imgs {
    margin: 0px 0 30px 0;
  }

  .landing-page .say-parts {
    flex-direction: column;
  }

  .landing-page .say {
    padding: 50px 0;
  }

  .landing-page .say-text {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 50px;
  }

  .landing-page .tooltip-visible:before,
  .landing-page .tooltip-visible:after {
    opacity: 1;
    visibility: visible;
  }

  .landing-page a[data-tooltip]:hover:before,
  .landing-page a[data-tooltip]:hover:after {
    opacity: 0;
    visibility: hidden;
  }

  .landing-page p {
    font-size: calc(1.38rem * 0.625);
  }

  .landing-page h1 {
    font-size: calc(3.5rem * 0.625);
  }

  .landing-page h2 {
    font-size: calc(3rem * 0.625);
  }

  .landing-page .btn {
    font-size: calc(1.08rem * 0.625);
  }

  .landing-page .btn--big {
    font-size: calc(1.33rem * 0.625);
  }

  .landing-page .text-md {
    font-size: calc(1.25rem * 0.625);
  }

  .landing-page .text-mdd {
    font-size: calc(1.66rem * 0.625);
  }

  .landing-page .text-lg {
    font-size: calc(1.91rem * 0.625);
  }

  .landing-page .oi-text-left p {
    font-size: calc(1.166rem * 0.625);
  }

  .landing-page .oi-text-right ul li,
  .landing-page .oi-text-right {
    font-size: calc(1.33rem * 0.625);
  }

  .landing-page .oi-text-left p {
    font-size: calc(1.166rem * 0.625);
  }

  .landing-page .discover {
    padding-top: calc(9.16rem * 0.625);
  }
}

@media (max-width: 700px) {
  .landing-page .discover__block {
    flex-direction: column;
  }

  .landing-page .discover__block-text,
  .landing-page .discover__block-image,
  .landing-page .discover__block-text-size {
    max-width: 100%;
  }

  .landing-page .discover__block-text {
    margin-bottom: 50px;
  }
}

@media (max-width: 600px) {
  .landing-page .footer-row {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .landing-page .footer-right__text {
    text-align: left;
  }

  .landing-page .footer-right {
    margin-bottom: 30px;
  }

  .landing-page footer {
    padding: 0px 0 25px 0;
  }


  .landing-page p {
    font-size: calc(1.38rem * 0.5625);
  }

  .landing-page h1 {
    font-size: calc(3.5rem * 0.5625);
  }

  .landing-page h2 {
    font-size: calc(3rem * 0.5625);
  }

  .landing-page .btn {
    font-size: calc(1.08rem * 0.5625);
  }

  .landing-page .btn--big {
    font-size: calc(1.33rem * 0.5625);
  }

  .landing-page .text-md {
    font-size: calc(1.25rem * 0.5625);
  }

  .landing-page .text-mdd {
    font-size: calc(1.66rem * 0.5625);
  }

  .landing-page .text-lg {
    font-size: calc(1.91rem * 0.5625);
  }

  .landing-page .oi-text-left p {
    font-size: calc(1.166rem * 0.5625);
  }

  .landing-page .oi-text-right ul li,
  .landing-page .oi-text-right {
    font-size: calc(1.33rem * 0.5625);
  }

  .landing-page .oi-text-left p {
    font-size: calc(1.166rem * 0.5625);
  }

  .landing-page .discover {
    padding-top: calc(9.16rem * 0.5625);
  }
}